import React, { useEffect, useState } from 'react'
import AxiosHelper from '../services/AxiosHelper';
import Logo from '../component/common/Logo';
import Serit from '../assets/image/serit.png'
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import { Band, HomeBlogPost, Packets, RegisterBox, Spacing, BeSlider } from '../component/ComponentExport';
import Program from '../component/Program';
import LogoIcon from '../assets/image/logo-icon.png';

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Helmet } from "react-helmet";
const Clubs = () => {
    const api = AxiosHelper();

    const [branches, setBranches] = useState();
    const [branchDetail, setBranchDetail] = useState();
    const [activeBranches, setActiveBranches] = useState();
    const [program, setProgram] = useState();
    useEffect(() => {
        api.get('?table_name=branches').then((res) => {
            setBranches(res);
            setTimeout(() => {
                document.querySelectorAll("#clubs button")[0].click();
            }, 300);
        })
    }, [])

    const [gallery, setGallery] = useState([]);

    const getBranchDetail = (id) => {
        setGallery([]);
        setActiveBranches(id)
        api.get(`api.php?branch_detail=${id}`).then((res) => {
            res.galleries.forEach((val) => {
                const img = new Image();
                img.src = "https://batuhankaan.com/musteri/gym-factory//" + val.photo_path;
                img.onload = () => {
                    setGallery((prevData) => [
                        ...prevData,
                        {
                            src: img.src,
                            width: img.width,
                            height: img.height
                        }
                    ]);
                };
            });
            setBranchDetail(res)
            setProgram(res.schedule);
        })
    }
    const [index, setIndex] = React.useState(-1);

    return (
        <div class="">
            <Helmet>
                <title>Kulüpler</title>
                <meta name="description" content="Gym Factory" />
                <meta name="keywords" content="" />
            </Helmet>
            <div className='max-sm:block max-sm:px-4 grid grid-cols-10 px-24 mt-8'>
                <div className='col-span-1 max-sm:hidden relative'>
                    <span className='outlined-text absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 -rotate-90 tracking-[50px] uppercase'>
                        {branchDetail && branchDetail.branch.branch_name}
                    </span>
                </div>
                <div className='col-span-6'>
                    <div id="clubs" className='flex justify-center my-3'>
                        {branches && branches.map((val) => {
                            return (
                                <button
                                    key={val.id}
                                    onClick={() => getBranchDetail(val.id)}
                                    className={`${activeBranches === val.id ? 'bg-primary text-white' : 'bg-white'} hover:bg-primary/90 p-2 font-medium border-stone-300 rounded-[20px]`}
                                >
                                    {val.branch_name}
                                </button>
                            );
                        })}
                    </div>
                    <h3 className='text-white text-center text-4xl mb-2'>KULÜPLER</h3>
                    <div className="gap-5 sm:gap-8">
                        <PhotoAlbum
                            layout="rows"
                            photos={gallery}
                            targetRowHeight={150}
                            onClick={({ index: current }) => setIndex(current)}
                        />
                        {gallery && console.log(gallery)}
                        <Lightbox
                            index={index}
                            slides={gallery}
                            open={index >= 0}
                            close={() => setIndex(-1)}
                        />
                    </div>
                </div>
                {branchDetail && <div className='col-span-2 text-white ml-5 max-sm:ml-0 max-sm:mt-5'>
                    <div>
                        <div className="bg-darkblack flex justify-between items-center">
                            <span className='uppercase px-3 font-medium text-xs'>{branchDetail.branch.branch_name} GYMFACTORY</span>
                            <div className='bg-primary p-1'>
                                <img src={LogoIcon} width={40} />
                            </div>
                        </div>
                        <div className='border-1 border-darkblack'>
                            <div className="border-b-1 border-darkblack text-center font-medium p-2">BİZ KİMİZ ? / HAKKIMIZDA</div>

                            <div>
                                <div className='flex justify-center'>
                                    <Logo />
                                </div>
                                <div className='text-grey p-3'>
                                    {branchDetail.branch.branch_text}
                                </div>
                                <div className='flex justify-center p-3'>
                                    <a href={branchDetail.branch.facebook_link} target='_blank' className='mx-2 bg-darkblack p-3 rounded-full hover:bg-primary duration-300'><FaFacebook /></a>
                                    <a href={branchDetail.branch.twitter_link} target='_blank' className='mx-2 bg-darkblack p-3 rounded-full hover:bg-primary duration-300'><FaTwitter /></a>
                                    <a href={branchDetail.branch.instagram_link} target='_blank' className='mx-2 bg-darkblack p-3 rounded-full hover:bg-primary duration-300'><FaInstagram /></a>
                                </div>
                            </div>
                            <div className='flex items-center border-t-1 border-b-1 border-darkblack p-2 justify-start'>
                                <img style={{ height: 10, width: 20 }} className='mr-2' src={Serit} />
                                <span className='font-medium'>ÇALIŞMA SAATLERI</span>
                            </div>

                            <div className='p-2 text-xs'>
                                <div className='text-primary flex justify-between border-b-1 border-dashed font-medium'>
                                    <span>PAZARTESİ</span>
                                    <span>{branchDetail.branch.monday_hours}</span>
                                </div>
                                <Spacing mh={15} />
                                <div className='text-primary flex justify-between border-b-1 border-dashed font-medium'>
                                    <span>SALI</span>
                                    <span>{branchDetail.branch.tuesday_hours}</span>
                                </div>
                                <Spacing mh={15} />
                                <div className='text-primary flex justify-between border-b-1 border-dashed font-medium'>
                                    <span>ÇARŞAMBA</span>
                                    <span>{branchDetail.branch.wednesday_hours}</span>
                                </div>
                                <Spacing mh={15} />
                                <div className='text-primary flex justify-between border-b-1 border-dashed font-medium'>
                                    <span>PERŞEMBE</span>
                                    <span>{branchDetail.branch.thursday_hours}</span>
                                </div>
                                <Spacing mh={15} />
                                <div className='text-primary flex justify-between border-b-1 border-dashed font-medium'>
                                    <span>CUMA</span>
                                    <span>{branchDetail.branch.friday_hours}</span>
                                </div>
                                <Spacing mh={15} />
                                <div className='text-primary flex justify-between border-b-1 border-dashed font-medium'>
                                    <span>CUMARTESİ</span>
                                    <span>{branchDetail.branch.saturday_hours}</span>
                                </div>
                                <Spacing mh={15} />
                                <div className='text-primary flex justify-between border-b-1 border-dashed font-medium'>
                                    <span>PAZAR</span>
                                    <span>{branchDetail.branch.sunday_hours}</span>
                                </div>
                            </div>

                            {/* <div className='flex items-center border-t-1 border-b-1 border-darkblack p-2 justify-start'>
                                <img style={{ height: 10, width: 20 }} className='mr-2' src={Serit} />
                                <span className='font-medium'>ETİKETLER</span>
                            </div>
                            <div className="text-grey flex flex-wrap">
                                <span className='border-b-1 border-grey mb-2 mr-2'>Weigtloss</span>
                                <span className='border-b-1 border-grey mb-2 mr-2'>Weigtloss</span>
                                <span className='border-b-1 border-grey mb-2 mr-2'>Weigtloss</span>
                                <span className='border-b-1 border-grey mb-2 mr-2'>Weigtloss</span>
                            </div> */}
                        </div>
                    </div>
                </div>}
            </div>
            <Spacing mh={20} />
            <Band />
            <RegisterBox />
            {branchDetail && <Program data={program} name={branchDetail.branch.branch_name} />}
            {branchDetail && <Packets data={branchDetail.packages} />}

            <HomeBlogPost />
        </div>
    )
}

export default Clubs