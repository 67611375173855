import React, { useEffect, useState } from 'react'
import { Band, Button2, Spacing } from './ComponentExport'
import SparklesCanvas from './Sparkles'
import Logo from './common/Logo'
import AxiosHelper from '../services/AxiosHelper'
import Elips from './common/Elips'

const RegisterIK = () => {
    const api = AxiosHelper();


    const [isim, setIsim] = useState();
    const [soyisim, setSoyisim] = useState();
    const [gsm, setGsm] = useState();
    const [born, setBorn] = useState();
    const [position, setPosition] = useState();
    const [resume, setResume] = useState();
    const [otherInfo, setOtherInfo] = useState();
    const [cv, setCv] = useState();



    const [responseMessage, setResponseMessage] = useState();

    const RegisterAction = () => {
        api.post('api.php', {}).then((res) => {
            setResponseMessage(res)
            if (res.status) {

            }
        })
    }

    return (
        <div>
            <div className='py-40 px-2 max-sm:py-8 max-sm:px-6 mb-32 max-sm:mb-4 bg-stone-900/60 relative'>
                <div className='absolute -z-10 right-0 top-0'>
                    <SparklesCanvas />
                </div>
                <div className='flex items-center flex-col'>
                    <Logo />
                    <h3 className='text-white text-center text-3xl max-sm:text-2xl font-medium mb-12'>İNSAN KAYNAKLARI</h3>
                </div>
                <Elips style={{ right: 10, left: 10 }} />
                <Elips style={{ right: 10, left: 10, top: 10 }} />
                <div className='px-24 z-50 max-sm:block max-sm:px-2 w-max flex flex-col m-auto'>
                    <div className="flex max-sm:block justify-between">
                        <div className='flex flex-col m-3'>
                            <span className='text-primary font-bold text-xs mb-3 ml-2'>İSİM</span>
                            <input required onChange={(e) => setIsim(e.currentTarget.value)} value={isim} className='placeholder:text-white rounded-[20px] text-white bg-gray-300/10 p-3' />
                        </div>
                        <div className='flex flex-col m-3'>
                            <span className='text-primary font-bold text-xs mb-3 ml-2'>SOYİSİM</span>
                            <input required onChange={(e) => setSoyisim(e.currentTarget.value)} value={soyisim} className='placeholder:text-white rounded-[20px] text-white bg-gray-300/10 p-3' />
                        </div>
                        <div className='flex flex-col m-3'>
                            <span className='text-primary font-bold text-xs mb-3 ml-2'>GSM</span>
                            <input required onChange={(e) => setGsm(e.currentTarget.value)} value={gsm} className='placeholder:text-white rounded-[20px] text-white bg-gray-300/10 p-3' />
                        </div>
                        <div className='flex flex-col m-3'>
                            <span className='text-primary font-bold text-xs mb-3 ml-2'>DOĞUM TARİHİ</span>
                            <input required onChange={(e) => setBorn(e.currentTarget.value)} value={born} className='placeholder:text-white rounded-[20px] text-white bg-gray-300/10 p-3' />
                        </div>
                        <div className='flex flex-col m-3'>
                            <span className='text-primary font-bold text-xs mb-3 ml-2'>ÇALIŞMAK İSTEDİĞİNİZ POZİSYON</span>
                            <input required onChange={(e) => setPosition(e.currentTarget.value)} value={position} className='placeholder:text-white rounded-[20px] text-white bg-gray-300/10 p-3' />
                        </div>
                    </div>
                    <div className='flex max-sm:block justify-between mt-8'>
                        <div className='flex flex-col m-3'>
                            <span className='text-primary font-bold text-xs mb-3 ml-2'>KISA ÖZGEÇMİŞ</span>
                            <textarea required onChange={(e) => setResume(e.currentTarget.value)} value={resume} className='placeholder:text-white rounded-[20px] text-white bg-gray-300/10 p-3' />
                        </div>
                        <div className='flex flex-col m-3'>
                            <span className='text-primary font-bold text-xs mb-3 ml-2'>EKLEMEK İSTEDİĞİNİZ DİĞER BİLGİLER</span>
                            <textarea required onChange={(e) => setOtherInfo(e.currentTarget.value)} value={otherInfo} className='placeholder:text-white rounded-[20px] text-white bg-gray-300/10 p-3' />
                        </div>
                        <div className='flex flex-col m-3'>
                            <span className='text-primary font-bold text-xs mb-3 ml-2'>CV'NİZİ YÜKLEYİN</span>
                            <input type="file" required onChange={(e) => setCv(e.currentTarget.value)} value={cv} className='placeholder:text-white rounded-[20px] text-white bg-gray-300/10 p-3' />
                        </div>

                    </div>
                    <button className='bg-primary px-10 py-4 hover:bg-primary/50 duration-300 text-white'>
                        <span className=''>GÖNDER</span>
                    </button>
                    {responseMessage &&
                        <div className='text-white'>
                            {responseMessage.message}
                        </div>}
                </div>
            </div>
            <Band />
            <br /><br /><br /><br />
        </div>
    )
}

export default RegisterIK