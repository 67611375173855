import React from 'react'

const InstagramPosts = () => {
    return (
        <div className='py-12 relative'>
            <div className='absolute inset-0 flex justify-center items-center'>
                <button className='bg-primary p-6 px-10'>
                    <span className='text-white'>INSTAGRAM</span>
                </button>
            </div>
            <div className='grid grid-cols-5 grid-rows-1 gap-0'>
                <div className='bg-zinc-800 h-64'>-</div>
                <div className='bg-zinc-700'> </div>
                <div className='bg-zinc-800'> </div>
                <div className='bg-zinc-700'> </div>
                <div className='bg-zinc-800'> </div>
            </div>
        </div>)
}

export default InstagramPosts