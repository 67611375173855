import React, { useEffect, useState } from 'react'
import { Button2, Spacing } from './ComponentExport'
import Serit from '../assets/image/serit.png'
import Elips from './common/Elips'
import AxiosHelper from '../services/AxiosHelper';
import Logo from './common/Logo';

const Packets = ({ data }) => {
  const api = AxiosHelper();
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    if (!data) {
      api.get('?table_name=membership_packages&param_name=branch_id&branch_id=7').then((res) => {
        setPackages(res);
      })
    }
  }, []);

  useEffect(() => {
    if (data) {
      setPackages(data)
    }
  }, [data])

  const colors = [
    'bg-neutral-900',
    'bg-neutral-900/50',
    'bg-neutral-800/50',
    'bg-neutral-700/50',
    'bg-neutral-600/50',
    'bg-neutral-500/50',
  ];

  return (
    <div className='py-32'>
      <div className='flex flex-col items-center'>
        <Logo />
        <Spacing mh={15} />
        <h3 className='text-white text-center text-3xl font-medium mb-12'>ÜYELİK PAKETİ AL</h3>
      </div>

      <div className="grid grid-cols-3 max-sm:grid-cols-1 grid-rows-1 gap-8 px-48 max-sm:px-0">
        <Elips style={{ left: 0 }} />
        {
          packages.map((data, index) => {
            return (
              <div key={index} className={`${colors[index]} py-5 px-10 relative`}>
                {index == 0 &&
                  (<div className='bg-primary rotate-90 px-4 top-10 right-0 absolute'>
                    <span className='text-white'>POPÜLER</span>
                  </div>)}
                <div className='flex flex-col'>
                  <span className='text-white text-3xl'>{data.title}</span>
                  {/* <Spacing mh={10} />
                  <span className='text-white text-4xl'>{data.price}₺/yıllık</span> */}
                </div>
                <Spacing mh={40} />
                <div>
                  {data.package_content.split(/[\r\n]+/).map((item, key) => {
                    return <div key={key}>
                      <div className='flex items-center'>
                        <img style={{ height: 10, width: 20 }} className='mr-2' src={Serit} />
                        <span className='text-stone-300'>{item}</span>
                      </div>
                      <Spacing mh={15} />
                    </div>
                  })}
                </div>
                <button
                  onClick={() => window.open('https://gymfactory-memberpos.lapiswellness.com/subeBilgileri', '_blank')}
                  className='bg-primary p-6 px-10 rounded-[20px]'
                >
                  <span className='text-white'>SATIN AL</span>
                </button>

              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Packets